// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBlwQYFT0FmnPrJP63JHUpclt6T9UN75Fs",
  authDomain: "azhagudroptaxi.firebaseapp.com",
  databaseURL: "https://azhagudroptaxi-default-rtdb.firebaseio.com",
  projectId: "azhagudroptaxi",
  storageBucket: "azhagudroptaxi.appspot.com",
  messagingSenderId: "625614120525",
  appId: "1:625614120525:web:2def2972f2bd9f5dbede04",
  measurementId: "G-TQ9W5WXSJS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
