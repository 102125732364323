import { Component, OnInit } from '@angular/core';
import { tamilnadu_districts } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  allCities = tamilnadu_districts;
  settings = SITE_SETTINGS

  constructor() { }

  ngOnInit(): void {
  }

}
