export const SITE_SETTINGS = {
    isCMS: true,
    isDatabaseEnable: false,
    isTelegramEnabled: false,
    isSMSEnabled: false,
    OTP: false,
    isRentalFixedPrice: false,
    isPushNotificationAPIEnabled: false,
    pushNotificationAPI: '',
    siteName: 'Azhagu Droptaxi',
    phoneNo: '7305388838',
    whatsapp: '+917305388838',
    websiteAddress: 'www.azhagudroptaxi.in',
    smsKey: 'uoWY1Jq3zvxmydDI7caXHepCblrEiMQLnVRSKj8T42Ag0GwkZBKzWDaLeP7s3fiMU5qZXCNvnpT9YjAx',
    enquiryEmail: 'azhagudroptaxi.in@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    customerEmailSubject: 'Booking Confirmation ',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '5224994576:AAGCF4sSdKkCFw4yfVnBZsVkBpSF9wuggyo',
    tGroup: 'droptaxioneway',
    phoneNumber: '7305388838',
    logoURL: '',
    OTPApi: ''
}


export const TIME = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
]
