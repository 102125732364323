<main id="main" class="site-main">
    <div class="page-title page-title--small align-left" style="background-image: url(assets/bg-checkout.png);">
        <div class="container">
            <div class="page-title__content">
                <h1 class="page-title__name">Outstation Taxi Fare</h1>
            </div>
        </div>
    </div><!-- .page-title -->
    <div class="site-content">
        <div class="pricing-area">
            <div class="container">
                <h2 class="title align-center">Find the cab that’s right for you</h2>
                <div *ngIf="tariff" class="pricing-inner">
                    <div class="row">
                        <div *ngFor="let car of tariff" class="col-lg-4">
                            <div class="pricing-item">
                                <img src="{{car.tariffImage}}" alt="Basic">
                                <h3>{{car.carType}}</h3>
                                <div class="price"><span class="currency">₹</span>{{car.oneway}}<span class="time">per
                                        km</span></div>
                                <a href="tel:{{settings.siteName}}" class="btn" title="Get Started">Book Now</a>
                                <ul>
                                    <li>One Way - Rs. {{car.oneway}}</li>
                                    <li>Round Trip- Rs. {{car.roundtrip}}</li>
                                    <li>Driver Allowance – Rs. {{car.onewayDriverFee}}/-
                                    </li>
                                    <li>One Way – Minimum 130 Km
                                    </li>
                                    <li>Round Trip – Minimum 250 Km
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div><!-- .pricing-inner -->
                <div class="payment-method">
                    <img src="assets/checkout.png" alt="" srcset="">
                    <p class="pt-4">Payment Methods We Accept</p>
                </div><!-- .payment-method -->
            </div>
        </div><!-- .pricing-area -->


        <div class="frequently-asked">
            <div class="container">
                <div class="title">
                    <h2>For customer information</h2>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <ul>
                            <li>
                                Tolls and (if applicable) Inter-State Permit fees are not included in the price. For a round
                                trip, the driver bata is Rs. 300/- per day.
                            </li>
                            <li>
                                Driver Cost Rs. 300 for Drop Trips [Rs. 600 for distances exceeding 400 km]
                            </li>
                            <li>
                                Charges for Hill Stations - Rs. 300
                            </li>
                            <li>
                                Round trips require a minimum daily running distance of 250 kilometres. It is a minimum of
                                300 km per day for Bengaluru.
                            </li>
                            <li>
                                Drop Trips - A daily running distance of 130 kilometres is required.
    
                            </li>
                            <li>
                                1 day corresponds to one calendar day (from midnight 12 to Next Midnight 12)
    
                            </li>
                            <li>
                                The waiting fee would be Rs.2 per minute.
    
                            </li>
                            <li>
                                (With the exception of 30 minutes for eating.)
    
                            </li>
                            <li>
                                Sedans have a maximum luggage capacity of three suitcases, whereas SUVs have a maximum
                                luggage capacity of four suitcases.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>


        <!-- <div class="frequently-asked mt-4">
            <div class="container">
                <div class="title">
                    <h2>Frequently Asked Questions</h2>
                    <p>Got questions? Maybe we have answers.</p>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="asked-item">
                            <h3>Is it possible to cancel my reservation?</h3>
                            <p>Yes. You have the option to cancel your reservation. However, if you cancel the booking
                                after the driver has already arrived at your destination, you must pay him Rs.300.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="asked-item">
                            <h3>Will my data be private and safe?</h3>
                            <p>Yes. Long answer: take a look at all the security measures we take to protect your
                                business.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="asked-item">
                            <h3>Is it possible for me to change my travel plans?</h3>
                            <p>Yes. You can adjust your journey at any time; the actual cost will change according to
                                the tariff plan.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="asked-item">
                            <h3>What happens if the taxi breaks down in the middle of the journey?</h3>
                            <p>We only hire Taxis that are in excellent working order as a rule. Minor repairs, such as
                                tyre deflation, can be completed in a matter of minutes. In the event of a major
                                breakdown, we will arrange for a replacement taxi. It is our goal to ensure that you get
                                at your destination with the least amount of time and discomfort possible.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</main>