import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/canonical.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { TaxiService } from 'src/app/components/taxi.service';
import { POPULAR_CITIES, POPULAR_ROUTES, tamilnadu_districts } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  firstFormGroup: FormGroup;
  submitted = false;
  settings = SITE_SETTINGS;
  places = POPULAR_CITIES;
  poplarRoutes = POPULAR_ROUTES as any;
  rideDetails;
  cars;
  carType;
  selectedCar;
  allCities = tamilnadu_districts;
  viewAllCities = false;
  subdomain;
  cityImageURL;
  title;

  constructor(private _formBuilder: FormBuilder, private router: Router, private taxiService: TaxiService, private http: HttpClient, public dialog: MatDialog, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {

    let getLink = window.location.href;
    this.subdomain = getLink.split(".")[0].replace('https://', '');

   // this.subdomain = 'chennai';

    console.log('this.subdomain', this.subdomain);

    this.canonicalService.setCanonicalURL();


    this.title = `${this.subdomain} Drop Taxi | Book One Way Taxi From ${this.subdomain}`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `${this.subdomain} Drop Taxi offers one way taxi service in Chennai. Book One Way Taxi from chennai at low cost. ` }
    );




    this.cityImageURL = this.allCities.filter(item => item.city.toLowerCase() === this.subdomain)[0];

    console.log('this.cityImageURL', this.cityImageURL);


    this.taxiService.tariff.subscribe(tariff => {
      if (tariff) {
        this.cars = tariff;
      }
    });


    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    });
  }

  get firstFormGroupValidation() { return this.firstFormGroup.controls; }

  continue() {
    this.submitted = true;
    if (this.firstFormGroup.invalid) {
      return
    }

    let msgBody = `
    Quick Enquiry\r\n
    Name: ${this.firstFormGroup.get('name').value}\r\n
    Mobile Number: ${this.firstFormGroup.get('phone').value}\r\n
    `
    let telegramBody = {
      message: msgBody,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    this.taxiService.firstFormGroup.next(this.firstFormGroup.value);
    this.router.navigateByUrl('/booking');
    console.log(this.firstFormGroup.value);
  }


  getRouteEstimation(carType) {
    this.selectedCar = this.cars.filter(car => car.carType === carType)[0];
    this.poplarRoutes.map(route => {
      route['estimation'] = Math.round(this.selectedCar.oneway * route.distance + this.selectedCar.onewayDriverFee);
    });
  }

  selectCar(type) {
    this.carType = type;
    this.getRouteEstimation(this.carType);
  }

  openDialog(route) {
    let data = {
      route,
      ...this.selectedCar
    }
    this.dialog.open(ModalComponent, {
      data: data
    });
  }

  viewAllCity() {
    this.viewAllCities = !this.viewAllCities;
  }

}
