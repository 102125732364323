import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SITE_SETTINGS } from 'src/app/settings';
import * as moment from 'moment';
import { TaxiService } from 'src/app/components/taxi.service';

@Component({
  selector: 'app-search-cabs',
  templateUrl: './search-cabs.component.html',
  styleUrls: ['./search-cabs.component.css']
})
export class SearchCabsComponent implements OnInit {
  settings = SITE_SETTINGS;
  cars;
  firstFormGroup;
  secondFormGroup;
  rideDetails;
  days;
  fareDetails = false;

  constructor(private http: HttpClient, private taxiService: TaxiService, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.taxiService.firstFormGroup.subscribe(res => {
      if (res) {
        this.firstFormGroup = res;
      }
    });

    this.taxiService.secondFormGroup.subscribe(res => {
      if (res) {
        this.secondFormGroup = res;
      }
    });

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
        this.rideDetails = {
          ...this.firstFormGroup,
          ...this.secondFormGroup
        }

        this.getEstimation(this.rideDetails);
      }
    })



  }

  continue(car) {
    let rideDetails = {
      ...this.rideDetails,
      ...car
    }
    
    this.taxiService.bookingConfirmation.next(rideDetails);
    this.router.navigateByUrl('/trip-confirmation')
  }

  getEstimation(routes) {

    let distance;

    if (routes.triptype === 'Round Trip') {
      let startDate = moment(moment(routes.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(routes.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      distance = (routes.distance * 2 <= 250) ? this.days * 250 : (routes.distance * 2 > 250 * this.days) ? routes.distance * 2 : 250 * this.days;
    }
    else {
      distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;
    }

    this.cars.forEach(car => {

      car['tripEstimation'] =
        (routes.triptype === 'One Way') ?
          car.oneway * distance + car.onewayDriverFee :
          car.roundtrip * distance + car.roundTripDriverFee * this.days;

      car['driverBatta'] =
        (routes.triptype === 'One Way') ? car.onewayDriverFee : car.roundTripDriverFee * this.days;

      car['driverBattaPerDay'] =
        (routes.triptype === 'One Way') ? car.onewayDriverFee : car.roundTripDriverFee;

      car['ratePerKM'] =
        (routes.triptype === 'One Way') ? car.oneway : car.roundtrip;

      car['distance'] = distance;

      car['baseFare'] =
        (routes.triptype === 'One Way') ? car.oneway * distance : car.roundtrip * distance;

      car['days'] = this.days;
    });

    console.log(this.cars);
  }

  showFare() {
    this.fareDetails = !this.fareDetails;
  }

}
