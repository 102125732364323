import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-outstation',
  templateUrl: './outstation.component.html',
  styleUrls: ['./outstation.component.css']
})
export class OutstationComponent implements OnInit {
  
  settings = SITE_SETTINGS;
  tripType = 'One Way';

  ngOnInit(): void {
  }

  tripTypeSelect(type) {
    this.tripType = type;
  }

}
