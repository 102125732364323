import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { BookingComponent } from './pages/booking/booking.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchCabsComponent } from './pages/search-cabs/search-cabs.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { TripConfirmationComponent } from './pages/trip-confirmation/trip-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'booking',
    component: BookingComponent
  },
  {
    path: 'search-cabs',
    component: SearchCabsComponent
  },
  {
    path: 'trip-confirmation',
    component: TripConfirmationComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'tariff',
    component: TariffComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'city/:url',
    component: CityDetailComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
