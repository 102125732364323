import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CanonicalService } from 'src/app/canonical.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { TaxiService } from 'src/app/components/taxi.service';
import { tamilnadu_districts } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.css']
})
export class CityDetailComponent implements OnInit {
  origin;
  destination;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  totalDistance;
  totalDuration;
  showDirection;
  cars = [];
  estimations = [];
  settings = SITE_SETTINGS;
  title;
  origincityImageURL;
  destinationcityImageURL
  allCities = tamilnadu_districts;
  rideDetails;
  routeDetails;
  tripType = 'One Way';
  days;
  showCars;

  constructor(private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta, private taxiService: TaxiService, private router: Router, public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
      }
    })
    this.canonicalService.setCanonicalURL();
    let routeUrl = this.activeRoute.snapshot.paramMap.get('url').split('-');
    this.origin = routeUrl[0].charAt(0).toUpperCase() + routeUrl[0].slice(1);

    this.origin = (this.origin === 'Salem') ? this.origin = 'Salem Airport' : this.origin;

    this.destination = routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1);

    (this.origin && this.destination) ? this.showDirection = true : this.showDirection = false;

    console.log(this.origin, this.destination);
    this.origincityImageURL = this.allCities.filter(item => item.city.toLowerCase() === this.origin.toLowerCase())[0]?.image;
    this.destinationcityImageURL = this.allCities.filter(item => item.city.toLowerCase() === this.destination.toLowerCase())[0]?.image;

    this.title = `Book ${this.origin} to ${this.destination} Outstation Drop taxi Cabs with ${this.settings.siteName}.`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Book ${this.origin} to ${this.destination} Outstation Drop Taxi Cabs with ${this.settings.siteName}. Get 10% Off on ${this.origin} to ${this.destination} with our one way outstation drop taxi service` }
    );

    (this.origin && this.destination) ? this.showDirection = true : this.showDirection = false;

  }

  onResponses(event) {
    this.routeDetails = {
      'tripType': this.tripType,
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.origin,
      'destinationCityName': this.destination,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text,
      'distance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "")
    }

    this.getEstimation(this.routeDetails);
    this.rideDetails = this.routeDetails;
    this.showCars = true;
  }

  getEstimation(routes) {

    let distance;

    if (routes.tripType === 'One Way') {
      distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;
    }

    if (routes.tripType === 'Round Trip') {
      let startDate = moment(moment(routes.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(routes.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      distance = (routes.distance * 2 <= 250) ? routes.distance = this.days * 250 : (routes.distance * 2 > 250 * this.days) ? routes.distance = routes.distance * 2 : routes.distance = 250 * this.days;
    }
    this.cars.forEach(car => {
      car['tripEstimation'] = (routes.tripType === 'One Way') ? car.oneway * distance + car.onewayDriverFee : car.roundtrip * distance + car.roundTripDriverFee * this.days;
      car['driverBatta'] = (routes.tripType === 'One Way') ? car.onewayDriverFee : car.roundTripDriverFee * this.days;
      car['ratePerKM'] = (routes.tripType === 'One Way') ? car.oneway : car.roundtrip;
      car['distance'] = routes.distance;
      car['baseFare'] = (routes.tripType === 'One Way') ? car.oneway * distance : car.roundtrip * distance;
      car['tripType'] = routes.tripType;
    });
  }

  openDialog(city) {
    let data = {
      ...this.routeDetails,
      ...city,
    }

    console.log(data)

    
    this.dialog.open(ModalComponent, {
      data: data
    });

  }

}
