<footer id="footer" class="footer">
    <div class="container">
        <div class="footer__top">
            <div class="row">
                <div class="col-lg-5">
                    <div class="footer__top__info">
                        <a title="Logo" href="01_index_1.html" class="footer__top__info__logo"><img
                                src="assets/logo.png" alt="Drop Taxi Oneway"></a>
                        <!-- <p class="footer__top__info__desc">Our very experienced drivers will guide you through what every city you are in. Point to point / day trips / long distance, you name it.

                                </p> -->
                        <div class="footer__top__info__app">
                            <a title="Google Play"
                                href="https://play.google.com/store/apps/details?id=com.supercabs.customerapp"
                                class="banner-apps__download__android"><img src="assets/images/assets/google-play.png"
                                    alt="Google Play"></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <aside class="footer__top__nav">
                        <h3>Operating City</h3>
                        <ul>
                            <li><a title="Chennai" href="https://chennai.azhagudroptaxi.com">Chennai</a></li>
                            <li><a title="Coimbatore" href="https://coimbatore.azhagudroptaxi.com">Coimbatore</a></li>
                            <li><a title="Bangalore" href="https://bangalore.azhagudroptaxi.com">Bangalore</a></li>
                            <li><a title="Madurai" href="https://madurai.azhagudroptaxi.com">Madurai</a></li>
                        </ul>
                    </aside>
                </div>
                <div class="col-lg-2">
                    <aside class="footer__top__nav">
                        <h3>Support</h3>
                        <ul>
                            <li><a title="Get in Touch" href="mailto:{{settings.enquiryEmail}}">Get in Touch</a></li>
                            <li><a title="Help Center" href="tel:{{settings.phoneNumber}}">Help Center</a></li>
                            <li><a title="Live chat" href="https://wa.me/{{settings.whatsapp}}">Live chat</a></li>
                        </ul>
                    </aside>
                </div>
                <div class="col-lg-3">
                    <aside class="footer__top__nav footer__top__nav--contact">
                        <h3>Contact Us</h3>
                        <p>Address: 255/2, Allappanur Road, Thenmudiyanur, Tiruvannamalai, Tamil
                            Nadu, 606707</p>
                        <p>Email: <a href="mailto:{{settings.enquiryEmail}}"
                                class="__cf_email__">{{settings.enquiryEmail}}</a></p>
                        <p>Phone: {{settings.phoneNumber}}</p>
                        <ul>
                            <li class="facebook">
                                <a title="Facebook" href="#">
                                    <i class="la la-facebook-f"></i>
                                </a>
                            </li>
                            <li class="twitter">
                                <a title="Twitter" href="#">
                                    <i class="la la-twitter"></i>
                                </a>
                            </li>
                            <li class="youtube">
                                <a title="Youtube" href="#">
                                    <i class="la la-youtube"></i>
                                </a>
                            </li>
                            <li class="instagram">
                                <a title="Instagram" href="#">
                                    <i class="la la-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </aside>
                </div>
            </div>
        </div>
        <!-- .top-footer -->
        <div class="footer__bottom">
            <p class="footer__bottom__copyright">2023 &copy; <a title="{{settings.siteName}}"
                    href="#">{{settings.siteName}}</a>. All rights reserved.</p>
        </div>
        <!-- .top-footer -->
    </div>
    <!-- .container -->
</footer>
<!-- site-footer -->