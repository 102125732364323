import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { map } from 'rxjs/operators';
import { AboutComponent } from './pages/about/about.component';
import { BookingComponent } from './pages/booking/booking.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { SearchCabsComponent } from './pages/search-cabs/search-cabs.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { TripConfirmationComponent } from './pages/trip-confirmation/trip-confirmation.component';
import { OutstationComponent } from './components/outstation/outstation.component';
import { OnewayComponent } from './components/oneway/oneway.component';
import { RoundComponent } from './components/roundtrip/roundtrip.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BookingComponent,
    SearchCabsComponent,
    TripConfirmationComponent,
    ContactComponent,
    ModalComponent,
    AboutComponent,
    PagenotfoundComponent,
    TariffComponent,
    CityDetailComponent,
    OutstationComponent,
    OnewayComponent,
    RoundComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDiKW9A5HHThlr3ncOMsvl8KcXnkq5q6Hs',
      libraries: ["places"]
    }),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    HttpClientModule,
    MatDialogModule,
    MatStepperModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
