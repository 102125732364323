import { Component, OnInit } from '@angular/core';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  settings = SITE_SETTINGS;
  
  constructor() { }

  ngOnInit(): void {
  }

}
