export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBlwQYFT0FmnPrJP63JHUpclt6T9UN75Fs",
    authDomain: "azhagudroptaxi.firebaseapp.com",
    databaseURL: "https://azhagudroptaxi-default-rtdb.firebaseio.com",
    projectId: "azhagudroptaxi",
    storageBucket: "azhagudroptaxi.appspot.com",
    messagingSenderId: "625614120525",
    appId: "1:625614120525:web:2def2972f2bd9f5dbede04",
    measurementId: "G-TQ9W5WXSJS"
  }
};
