<div class="site-content owner-content">

    <div class="container pb-3">
        <div class="member-wrap pt-4">
            <div class="upgrade-box">
                <h1>Hello! {{(name) ? name: ''}}</h1>
                <p>Start planning your outstation travel with {{settings.siteName}}</p>
                <a href="tel:{{settings.phoneNumber}}" class="btn" title="Upgrade now">Need Help ?</a>
                <img src="assets/img-people.svg" alt="Upgrade now">
            </div>

            <aside class="widget widget-shadow widget-reservation">
                <h3 style="color: #2d2d2d;">Make a reservation</h3>
                <form [formGroup]="secondFormGroup" (ngSubmit)="continue()" class="form-underline">
                    <div class="widget widget-sb-detail">
                        <div class="button-wrap">
                            <div class="button"><a (click)="selectTrip('One Way')" class="btn"
                                    [ngClass]="{'btn-border': triptype !=='One Way'}">One Way</a></div>
                            <div class="button"><a (click)="selectTrip('Round Trip')" class="btn"
                                    [ngClass]="{'btn-border': triptype !=='Round Trip'}">Round Trip</a></div>
                        </div>
                    </div>
                    <div class="field-select has-sub field-guest">
                        <span class="sl-icon"><i class="las la-map-marker-alt"></i></span>
                        <input type="text" placeholder="Pickup Address" #origin>

                    </div>
                    <div class="field-select field-date">
                        <span class="sl-icon"><i class="las la-map-marker-alt"></i></span>
                        <input type="text" placeholder="Drop Address" #destination>

                    </div>
                    <div class="field-select has-sub field-time">
                        <span class="sl-icon"><i class="las la-calendar"></i></span>
                        <input type="text" placeholder="Pickup Date" formControlName="pickupDate" [min]="minDate"
                            (click)="picker.open()" [matDatepicker]="picker" readonly>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                    <div class="field-select has-sub field-time">
                        <span class="sl-icon"><i class="la la-clock"></i></span>

                        <mat-select formControlName="pickupTime" placeholder="Time">
                            <mat-option *ngFor="let time of times" [value]="time" [disabled]="isDisableTime(time)">
                                {{time}}
                            </mat-option>
                        </mat-select>

                    </div>
                    <div *ngIf="triptype === 'Round Trip'" class="field-select has-sub field-time">
                        <span class="sl-icon"><i class="las la-calendar"></i></span>
                        <input type="text" placeholder="Return Date"
                            [min]="secondFormGroupValidation['pickupDate'].value" (focus)="picker1.open()"
                            [matDatepicker]="picker1" matInput placeholder="Retun date" formControlName="returnDate"
                            readonly>
                        <mat-datepicker #picker1></mat-datepicker>
                    </div>
                    <input type="submit" name="submit" value="Search Cabs">
                </form>
                <agm-map class="hide" [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                    <ng-container *ngIf="showDirection">
                        <agm-direction [origin]="pickupPlace" [destination]="dropPlace"
                            (onResponse)="onResponses($event)">
                        </agm-direction>
                    </ng-container>
                </agm-map>
            </aside>
        </div><!-- .member-wrap -->
    </div>
</div>