<main id="main" class="site-main">
    <div class="page-title page-title--small align-left" style="background-image: url(assets/bg-about.png);">
        <div class="container">
            <div class="page-title__content">
                <h1 class="page-title__name">About</h1>
                <p class="page-title__slogan">{{settings.siteName}}</p>
            </div>
        </div>
    </div><!-- .page-title -->
    <div class="site-content">
        <div class="container">
            <!-- <div class="company-info flex-inline">
                <div class="ci-content">
                    <h2>PROFESSIONALISM</h2>

                    <img src="assets/drivers.jpg" alt="Our Company">
                    <p class="pt-4">
                        Our experienced staff and highly professional drivers are committed to providing safe, reliable
                        transportation, exceptional customer service, and consistent pricing. We provide in-house
                        dispatchers who support our clients.


                    </p>
                </div>
                <div class="ci-content">
                    <h2>COMMITMENT</h2>
                    <img src="assets/commitment.jpg" alt="Our Company">

                    <p class="pt-4">
                        Our office is open twenty four hours a day, seven days a week. You name the occasion – we’ll
                        make the ride flawless. Whether you require a outstation taxi for business or pleasure,
                        close to home or in a new city we are always there.
                    </p>
                </div>
            </div> -->

            <div class="join-team align-center mt-5">
                <div class="container">
                    <h2 class="title">{{settings.siteName}}</h2>
                    <div class="jt-content">
                        <p> We offers outstation taxi service in major parts of Tamil Nadu & karnataka. We've have around 7+ yrs of experience in outstation taxi service. We provide One Way & Round Trip taxi in which customers can save upto 40% </p>
                    </div>

                    <h2 class="title">Hire Us</h2>
                    <div class="jt-content">
                        <p>We’re always looking forward to fulfill your journey </p>
                        <a href="tel:{{settings.siteName}}" class="btn" title="View openings">Contact Us</a>
                    </div>
                </div>
            </div><!-- .join-team -->
        </div>
    </div><!-- .site-content -->
</main>