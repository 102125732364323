<main id="main" class="site-main">
    <div class="blog-banner">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
            <ng-container *ngIf="showDirection">
                <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
                </agm-direction>
            </ng-container>
        </agm-map>
    </div><!-- .blog-banner -->
    <div class="blog-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="blog-left">

                        <div class="entry-content">
                            <h1>Book {{origin.replace('Airport', '')}} to {{this.destination}} Outstation Drop taxi Cabs with
                                {{settings.siteName}}.</h1>

                            <div class="entry-desc">
                                <p>Book {{origin.replace('Airport', '')}} to {{this.destination}} Outstation Drop taxi Cabs with
                                    {{settings.siteName}}.


                                </p>
                                <p>
                                    Planning to ride {{origin.replace('Airport', '')}} to {{this.destination}}, we have got a cab for
                                    you. Whether you are planning a weekend getaway with your family or friends or you
                                    currently have to reach out to some particular location at the earliest, just book a
                                    ride with {{settings.siteName}} to access the best outstation taxi services. Our
                                    outstation taxi services
                                    will help you in exploring the best destination without any failure. Don't miss the
                                    opportunity to check out the must-see places from {{origin.replace('Airport', '')}} to {{this.destination}}
                                    on the way.


                                </p>

                                <p class="entry-image columns-2">
                                    <img *ngIf="origincityImageURL" src="{{origincityImageURL}}" alt="{{origin.replace('Airport', '')}} to {{this.destination}} Outstation Drop taxi Cabs with
                                    {{settings.siteName}}.">
                                    <img *ngIf="destinationcityImageURL" src="{{destinationcityImageURL}}" alt="{{origin.replace('Airport', '')}} to {{this.destination}} Outstation Drop taxi Cabs with
                                    {{settings.siteName}}.">
                                </p>
                                <h2>
                                    Book {{origin.replace('Airport', '')}} to {{this.destination}} One Way & Round Trip Outstation Drop taxi
                                </h2>
                                <p class="pt-3">
                                    {{settings.siteName}} offers the cheapest {{origin.replace('Airport', '')}} to {{this.destination}}
                                    Outstation taxi at a price. Get the
                                    best car rental rates from {{origin.replace('Airport', '')}} to {{this.destination}}. Choose from a wide
                                    range of
                                    vehicle models, including the Innova, Dzire, Etios, Indigo, sedans, SUVs, and other
                                    high-end vehicles. The best deals for a one-way or round-trip flight from Chennai to
                                    Coimbatore Booking of AC or Non-AC Cabs
                                </p>

                                <h2>
                                    Why Book {{origin.replace('Airport', '')}} to {{this.destination}} One Way taxi with {{settings.siteName}} ?
                                </h2>

                                <p class="pt-3">
                                    Special one-way prices are available for those who only want to be dropped off at
                                    their destination.
                                </p>
                                <p>
                                    Drivers with plenty of experience and courtesy, as well as well-maintained,
                                    comfortable cabs
                                </p>
                            </div><!-- .entry-desc -->

                        </div><!-- .entry-content -->

                        <!-- .place__box -->
                    </div><!-- .place__left -->
                </div>

            </div>
        </div>


        <section *ngIf="routeDetails" class="cuisine-wrap section-bg">
            <div class="container">
                <div class="widget widget-post">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="widget-title">Book {{origin.replace('Airport', '')}} To {{destination.replace('Airport', '')}}
                                Outstation Drop Taxi Cabs</h3>
                            <p>
                                Choose your vehicle type
                            </p>
                        </div>
                    </div>
                    <div class="widget-content mt-3">
                        <div class="row">
                            <ng-container *ngFor="let car of cars">
                                <div class="col-md-4">
                                    <article class="post">
                                        <img src="{{car.tariffImage}}" alt="{{car.carType}}">
                                        <div class="post__info">
                                            <ul class="post__category">
                                                <li><a title="Shopping" href="#">
                                                        <h2>{{car.carType}}</h2>
                                                    </a>
                                                </li>
                                                <li>
                                                    <h2>   Rs. {{car.tripEstimation}}</h2>
                                                 
                                                </li>
                                            </ul>

                                            <a class="btn" (click)="openDialog(car)"  style="
                                        height: 30px;
                                        line-height: 30px;
                                        padding: 0 10px;
                                        margin-top: 10px;
                                    "> Book Now</a>
                                        </div>
                                    </article>
                                </div>
                            </ng-container>


                        </div>
                    </div>
                </div>

                <div class="button-wrap">
                    <a class="btn" style="  background-color: #2d2d2d;" href="tel:{{settings.phoneNumber}}"
                        title="View more"> Need Help ?</a>
                </div>

            </div>
        </section>
    </div><!-- .blog-content -->
</main>