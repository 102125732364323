<main id="main" class="site-main">
    <div class="container pb-3">
        <div class="row">
            <div class="col-md-6">
                <div class="cs-info">
                    <h1>Booking<br> <span>Successfull</span></h1>
                    <p>We have received your booking and our representative will contact you shortly
                    </p>
                    <p><b>For more inquiry: </b><a href="tel:{{settings.phoneNumber}}">+91-{{settings.phoneNumber}}</a></p>
                </div><!-- .cs-info -->
            </div>
            <div class="col-md-6">
                <div class="cs-thumb">
                    <img src="assets/img-people.svg" alt="Coming Soon">
                </div><!-- .cs-thumb -->
            </div>
        </div>
    </div>
</main>