<div *ngIf="rideDetails" class="site-content owner-content">
    <div class="member-menu">
        <div class="container text-center">
            <ul>
                <li><a href="owner-dashboard.html">{{rideDetails.originCityName}}</a></li>
                <li><a href="owner-dashboard.html">
                        <i class="las la-arrow-alt-circle-right"></i>
                    </a></li>
                <li><a href="owner-dashboard.html">{{rideDetails.destinationCityName}}</a></li>

            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sidebar sidebar--border">
            <aside class="sidebar--shop__item widget">
                <h3 class="sidebar--shop__title">Lowest Fare Guaranteed</h3>
                <div class="sidebar--shop__content">
                    <div *ngFor="let car of cars">
                        <div class="product-small">
                            <div class="product-small__thumb">
                                <a title="{{car.carType}}" href="#">
                                    <img src="{{car.tariffImage}}" alt="{{car.carType}}">
                                </a>
                            </div>
                            <div class="product-small__info">
                                <h3><a title="Monggolia Travel Guide" href="#">{{car.carType}}</a></h3>
                                <div class="product-small__price"><span>₹ {{car.tripEstimation}}</span></div>
                            </div>
                            <div>
                                <div class="field-group field-submit">
                                    <a (click)="continue(car)" class="btn">Select</a>
                                    <span (click)="showFare()" class="fare">Fare breakup</span>
                                </div>
                            </div>
                        </div>
                        <table *ngIf="fareDetails" class="member-place-list owner-booking table-responsive">
							<thead>
								<tr>
									<th>Base Fare</th>
									<th>Rate Per KM</th>
									<th>Driver Allowance</th>
									<th>Total Fare</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td data-title="Base Fare">₹ {{car.baseFare}}</td>
									<td data-title="Rate Per KM"><b>₹ {{car.ratePerKM}}</b></td>
									<td data-title="Driver Allowance">₹ {{car.driverBatta}}</td>
									<td data-title="Total Trip Fare" class="approved">₹ {{car.tripEstimation}}</td>
								</tr>
								
								
								
								
							</tbody>
						</table>
                    </div>
                 
                  
                 

                </div>
            </aside><!-- .sidebar--shop__item -->

        </div>
    </div>
</div>