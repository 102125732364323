<main *ngIf="cityImageURL" id="main" class="site-main">
    <div class="page-title" style="background-image: url({{cityImageURL.image}});">
        <div class="container">
            <div class="page-title__content">
                <h4 class="page-title__capita">{{subdomain}} One Way Taxi</h4>
                <h1 class="page-title__name">{{subdomain}} Drop Taxi</h1>
                <p>Book a drop taxi in {{subdomain}} at {{settings.siteName}}. {{settings.siteName}} is one of the
                    leading cab
                    service in {{subdomain}}, provides its services between various locations across Tamil Nadu at
                    cheapest
                    price.</p>
                <a href="tel:{{settings.phoneNumber}}" class="btn mt-3 "> CALL +91-{{settings.phoneNumber}}</a>
            </div>
        </div>
    </div>
    <!-- .page-title -->
    <div class="intro">
        <div class="container">
            <h1>Book drop taxi</h1>
            <p>Enter Name & Mobile to book your cab</p>
            <app-outstation></app-outstation>


            <h2 class="title">{{subdomain}} Outstation Drop Taxi Service </h2>
            <h3>
                Are you looking for {{subdomain}} Outstation Drop Taxi ?
            </h3>
            <div class="intro__content">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="intro__text">

                            Book best outstation taxi services in {{subdomain}},
                            {{subdomain}} Drop Taxi One Way is one
                            of the most trusted and reliable options you could choose. It is a well-established car
                            service provider in Tamil Nadu that specializes in comprehensive long and short-term car
                            rental
                            solutions at an easier end. Whether you have to go to any other state or have to move around
                            the different nearby locations, {{subdomain}} Drop Taxi is an option that ensures users have
                            the
                            best services always.
                        </div>
                    </div>
                </div>
            </div>
            <!-- .intro__content -->
        </div>
    </div>

    <!-- .intro -->

    <section *ngIf="poplarRoutes" class="cuisine-wrap section-bg">
        <div class="container">
            <div class="widget widget-post">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="widget-title">{{subdomain}} Outstation Drop Taxi Routes</h3>
                        <p>
                            We believe in versatility and to ensure that, we have added on different types of cars in
                            our taxi booking services. Whether you have to book a sedan car or you are looking forward
                            to luxury SUV car services, we owe to provide you every option with the best only. Right
                            from very basic to the luxury car options we have added different taxis in our services.
                        </p>
                    </div>
                </div>
                <div class="widget-content mt-3">
                    <div class="row">
                        <ng-container *ngFor="let route of allCities">
                            <div *ngIf="route.city.toLowerCase() !== subdomain" class="col-md-4">
                                <article class="post">
                                    <img src="{{route.image}}" alt="{{subdomain}} to {{route.city}}">
                                    <div class="post__info">
                                        <ul class="post__category">
                                            <li><a title="{{subdomain}} to {{route.city}} taxi"
                                                    routerLink="/city/{{subdomain}}-to-{{route.city.toLowerCase()}}-outstation-drop-taxi-cabs">
                                                    <h2>{{subdomain}} to {{route.city}}
                                                        Drop Taxi</h2>
                                                </a></li>
                                        </ul>

                                        <a routerLink="/city/{{subdomain}}-to-{{route.city.toLowerCase()}}-outstation-drop-taxi-cabs"
                                            class="btn" style="
                                        height: 30px;
                                        line-height: 30px;
                                        padding: 0 10px;
                                        margin-top: 10px;
                                    "> Book Now</a>
                                    </div>
                                </article>
                            </div>
                        </ng-container>


                    </div>
                </div>
            </div>

            <div class="button-wrap">
                <a class="btn" style="  background-color: #2d2d2d;" href="tel:{{settings.phoneNumber}}"
                    title="View more"> Need Help ?</a>
            </div>

        </div>
    </section>

    <!-- .city-content -->
    <div class="other-city banner-dark">
        <div class="container">
            <h2 class="title title--while">Explorer Other Cities</h2>
            <div class="other-city__content">
                <div class="row">
                    <div *ngFor="let item of places" class="col-lg-3 col-md-6">
                        <div class="cities__item hover__box">
                            <div class="cities__thumb hover__box__thumb">
                                <a title="New York" href="https://{{item.cityName}}.azhagudroptaxi.com">
                                    <img src="{{item.image}}" alt="newyork">
                                </a>
                            </div>
                            <h4 class="cities__name">Drop Taxi</h4>
                            <div class="cities__info">
                                <h3 class="cities__capital">{{item.cityName}}</h3>
                            </div>
                        </div>
                        <!-- .cities__item -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container cityList">
        <div class="SeoInterLinkingstyles__InterlinkingContainer-sc-sdo8i9-0 gwWKmY">
            <div class="SeoInterLinkingstyles__InterlinkingItemContainer-sc-sdo8i9-1 hkfRSw"><span
                    class="SeoInterLinkingstyles__InterlinkingItemTitle-sc-sdo8i9-2 fTrHOc">Cabs from
                    {{subdomain}}</span><a routerLink="/city/{{subdomain}}-to-pollachi-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Pollachi Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-nandi hills-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Nandi hills Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-rameswaram-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Rameswaram Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-hogenakkal-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Hogenakkal Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-nagari-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Nagari Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-chikballapur-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Chikballapur
                    Cabs | </a><a routerLink="/city/{{subdomain}}-to-mananthavady-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Mananthavady
                    Cabs | </a><a routerLink="/city/{{subdomain}}-to-yelagiri-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Yelagiri Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-rajampet-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Rajampet Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-gobichettipalayam-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To
                    Gobichettipalayam Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-srirangam-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Srirangam Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-gudiyattam-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Gudiyattam Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-proddatur-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Proddatur Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-palacode-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Palacode Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-mannargudi-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Mannargudi Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-karaikudi-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Karaikudi Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-sirkazhi-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Sirkazhi Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-polur-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Polur Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-rajapalayam-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Rajapalayam Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-chandragiri-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Chandragiri Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-sullurupeta-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Sullurupeta Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-tirukoilur-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Tirukoilur Cabs
                    | </a><a routerLink="/city/{{subdomain}}-to-renigunta-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Renigunta Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-ponnur-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Ponnur Cabs |
                </a><a routerLink="/city/{{subdomain}}-to-avinashi-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{subdomain}} To Avinashi
                    Cabs</a></div>
            <div class="SeoInterLinkingstyles__InterlinkingItemContainer-sc-sdo8i9-1 hkfRSw"><span
                    class="SeoInterLinkingstyles__InterlinkingItemTitle-sc-sdo8i9-2 fTrHOc">Cabs to
                    {{subdomain}}</span><a routerLink="/city/rameshwaram-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Rameshwaram To {{subdomain}} Cabs
                    | </a><a routerLink="/city/renigunta-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Renigunta To {{subdomain}} Cabs |
                </a><a routerLink="/city/sankarapuram-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sankarapuram To {{subdomain}}
                    Cabs | </a><a routerLink="/city/sirkazhi-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sirkazhi To {{subdomain}} Cabs |
                </a><a routerLink="/city/sivaganga-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sivaganga To {{subdomain}} Cabs |
                </a><a routerLink="/city/srikalahasti-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Srikalahasti To {{subdomain}}
                    Cabs | </a><a routerLink="/city/sringeri-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sringeri To {{subdomain}} Cabs |
                </a><a routerLink="/city/sriperumbudur-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sriperumbudur To {{subdomain}}
                    Cabs |
                </a><a routerLink="/city/srirangam-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Srirangam To {{subdomain}} Cabs |
                </a><a routerLink="/city/sullurupeta-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Sullurupeta To {{subdomain}} Cabs
                    | </a><a routerLink="/city/tambaram-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Tambaram To {{subdomain}} Cabs |
                </a><a routerLink="/city/thanjavur-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Thanjavur To {{subdomain}} Cabs |
                </a><a routerLink="/city/theni-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Theni To {{subdomain}} Cabs |
                </a><a routerLink="/city/thuraiyur-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Thuraiyur To {{subdomain}} Cabs |
                </a><a routerLink="/city/tirukoilur-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Tirukoilur To {{subdomain}} Cabs
                    | </a><a routerLink="/city/tirumala-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Tirumala To {{subdomain}} Cabs |
                </a><a routerLink="/city/tirupattur-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Tirupattur To {{subdomain}} Cabs
                    | </a><a routerLink="/city/tiruttani-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Tiruttani To {{subdomain}} Cabs |
                </a><a routerLink="/city/trichy-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Trichy To {{subdomain}} Cabs |
                </a><a routerLink="/city/vandavasi-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Vandavasi To {{subdomain}} Cabs |
                </a><a routerLink="/city/varadaiahpalem-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Varadaiahpalem To {{subdomain}}
                    Cabs |
                </a><a routerLink="/city/velankanni-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Velankanni To {{subdomain}} Cabs
                    | </a><a routerLink="/city/vijayawada-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Vijayawada To {{subdomain}} Cabs
                    | </a><a routerLink="/city/villupuram-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Villupuram To {{subdomain}} Cabs
                    | </a><a routerLink="/city/virudhachalam-to-{{subdomain}}-cabs/"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">Virudhachalam To {{subdomain}}
                    Cabs</a>
            </div>


            <div class="SeoInterLinkingstyles__InterlinkingItemContainer-sc-sdo8i9-1 hkfRSw"><span
                    class="SeoInterLinkingstyles__InterlinkingItemTitle-sc-sdo8i9-2 fTrHOc">Outstation Taxi
                    services</span>

                <a *ngFor="let city of allCities" href="https://{{city.city.toLowerCase}}.azhagudroptaxi.com"
                    class="SeoInterLinkingstyles__InterlinkingItem-sc-sdo8i9-3 doYruZ">{{city.city}} Cab Services | </a>


            </div>




        </div>
    </div>


    <!-- .other-city -->
</main>
<!-- .site-main -->