<main id="main" class="site-main contact-main">
    <div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-about.png);">
        <div class="container">
            <div class="page-title__content">
                <h1 class="page-title__name">Contact Us</h1>
                <p class="page-title__slogan">We want to hear from you.</p>
            </div>
        </div>	
    </div><!-- .page-title -->
    <div class="site-content site-contact">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="contact-text">
                        <h2>Our Offices</h2>
                        <!-- <div class="contact-box">
                            <h3>Salem</h3>
                            <p>102/32 minnampalli post Salem, Tamil Nadu 636106</p>
                           
                            <a href="tel:{{settings.phoneNumber}}" title="Get Direction"> +91-{{settings.phoneNumber}}</a>
                        </div> -->
                        <div class="contact-box">
                            <!-- <h3>Chennai</h3> -->
                            <p>No, 1 / 2341 c, 2 nd Street, South Bethel Nagar, Injambakkam Chennai - 115.</p>
                            <p>{{settings.phoneNumber}}</p>
                            <a href="tel:{{settings.phoneNumber}}" title="Get Direction">Get Direction</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="assets/contact.jpg" alt="" srcset="">

                </div>
            </div>
        </div>
    </div><!-- .site-content -->
</main>