import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SITE_SETTINGS, TIME } from 'src/app/settings';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  firstFormGroup: FormGroup;
  submitted = false;
  settings = SITE_SETTINGS;
  minDate = new Date();
  times = TIME;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private _formBuilder: FormBuilder, private http: HttpClient, private router: Router, private dateAdapter: DateAdapter<Date>,

    public dialogRef: MatDialogRef<ModalComponent>) {
    this.dateAdapter.setLocale('en-GB');
    console.log('data', this.data)
  }


  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
    });
  }

  get firstFormGroupValidation() { return this.firstFormGroup.controls; }

  bookNow() {
    this.submitted = true;

    if (this.firstFormGroup.invalid) {
      return
    }

    let bookingDetails = {
      ...this.firstFormGroup.value,
      ...this.data
    }

    let wpMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${Math.random().toString(36).substr(2, 9).toLocaleUpperCase()}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phone}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${moment(bookingDetails.pickupDate).format('DD/MM/YYYY')} ${bookingDetails.pickupTime} \r\n
    Trip Type: One Way \r\n
    Cab Type: ${bookingDetails.carType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.tripEstimation} ₹\r\n
    Rate Per KM: ${bookingDetails.oneway} ₹\r\n
    Driver Batta: ${bookingDetails.onewayDriverFee} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNumber} \r\n 
    https://${this.settings.websiteAddress}`

    let whatsappMessage = window.encodeURIComponent(wpMessage);

    let telegramBody = {
      message: wpMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    let SMSBODY = `
    Hi ${bookingDetails.name}, Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNumber}.
    `

    let smsBody = {
      message: SMSBODY,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    if (this.settings.isSMSEnabled) {
      this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

    this.dialogRef.close();

    this.router.navigateByUrl('/trip-confirmation');
  }

  isDisableTime(selectedTime: string): boolean {
    if (moment(this.firstFormGroup.get('pickupDate').value).isSame(Date.now(), 'day')) {
      let isTimeOver = false;
      let currentHour = new Date().getHours() + 2;
      let currentMin = new Date().getMinutes();
      let currentAmPm = currentHour >= 12 ? 'PM' : 'AM';
      currentHour = currentHour % 12;
      let selectedHour = +selectedTime.substr(0, 2) % 12;
      let selectedMin = +selectedTime.substr(3, 2);
      let selectedAM_PM = selectedTime.substr(6, 2);
      if (currentAmPm === selectedAM_PM) {
        if (selectedHour < currentHour) {
          isTimeOver = true;
        } else if (selectedHour === currentHour) {
          if (selectedMin < currentMin) {
            isTimeOver = true;
          }
        }
      } else {
        isTimeOver = currentAmPm > selectedAM_PM;
      }
      return isTimeOver;
    } else {
      return false
    }
  }

  close() {
    this.dialogRef.close();
  }


}
