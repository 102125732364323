<div *ngIf="data">
    <a title="Close" (click)="close()" class="popup__close">
        <i class="las la-times la-24-black"></i>
    </a><!-- .popup__close -->
    <p class="choose-more"><a title="Google" class="gg">Book {{data.origin}} to {{data.destination}} @ Just
            ₹
            {{data.tripEstimation}}</a></p>
    <div class="popup-content popup-form">
        <form [formGroup]="firstFormGroup" (ngSubmit)="bookNow()" class="form-sign form-content" id="signup">
            <div class="field-input">
                <input type="text" placeholder="Enter Name" formControlName="name">
                <p *ngIf="submitted && firstFormGroupValidation['name'].errors" class="text-danger">Enter valid name</p>

            </div>
            <div class="field-input">
                <input type="number" placeholder="Enter Mobile Number" formControlName="phone">
                <p *ngIf="submitted && firstFormGroupValidation['phone'].errors" class="text-danger">Enter valid mobile
                    number</p>
            </div>
            <div class="field-inline">
                <div class="field-input">
                    <input type="text" placeholder="Pickup Date" formControlName="pickupDate" [min]="minDate"
                        (click)="picker.open()" [matDatepicker]="picker" readonly>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="field-input">
                    <mat-select formControlName="pickupTime" placeholder="Time">
                        <mat-option *ngFor="let time of times" [value]="time" [disabled]="isDisableTime(time)">
                            {{time}}
                        </mat-option>
                    </mat-select>
                    <p *ngIf="submitted && firstFormGroupValidation['pickupTime'].errors" class="text-danger">Select
                        valid time</p>
                </div>
            </div>

            <input type="submit" name="submit" value="Book Now">
        </form>
    </div>

</div>