import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TaxiService } from 'src/app/components/taxi.service';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-trip-confirmation',
  templateUrl: './trip-confirmation.component.html',
  styleUrls: ['./trip-confirmation.component.css']
})
export class TripConfirmationComponent implements OnInit {
  settings = SITE_SETTINGS;
  bookingDetails;

  constructor(private http: HttpClient, private taxiService: TaxiService, private router: Router) { }

  ngOnInit(): void {
    this.taxiService.bookingConfirmation.subscribe(res => {
      if (res) {
        this.bookingDetails = { bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase(), ...res };
        this.bookNow();
      }
    })
  }

  bookNow() {

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('DD/MM/YYYY'),
      'bookedDateISO': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'baseFare': this.bookingDetails.baseFare,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.triptype,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('DD/MM/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNumber,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Website Confirmation From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail
    }

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate}`

    let wpMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNumber} \r\n 
    https://${this.settings.websiteAddress}`
    
    let whatsappMessage = window.encodeURIComponent(wpMessage);
    
    let SMSBODY = `
    Hi ${bookingDetails.name}, Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNumber}.
    `
    
    let smsBody = {
      message: SMSBODY,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }
    
    let telegramBody = {
      message: wpMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }
    
    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }
    
    if (this.settings.isSMSEnabled) {
      this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));
    }
    
    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));
    
    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

  }

}
