import { Component, OnInit } from '@angular/core';
import { TaxiService } from 'src/app/components/taxi.service';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {
  tariff;
  settings = SITE_SETTINGS
  constructor(private taxiService: TaxiService) { }

  ngOnInit(): void {
    this.taxiService.tariff.subscribe(res => {
      if (res) {
        this.tariff = res;
      }
    })
  }

}
