import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TaxiService } from 'src/app/components/taxi.service';
import { SITE_SETTINGS, TIME } from 'src/app/settings';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;

  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  settings = SITE_SETTINGS;
  secondFormGroup: FormGroup;
  submitted = false;
  triptype = 'One Way';
  origin;
  destination
  originCityName;
  destinationCityName;
  pickupPlace;
  dropPlace;
  selectedCar;
  requestRide;
  routeDetails;
  name;
  minDate = new Date();
  times = TIME;
  constructor(private _formBuilder: FormBuilder, private router: Router, private taxiService: TaxiService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {

    this.taxiService.firstFormGroup.subscribe(res => {
      if (res) {
        this.name = res.name;
      }
    })
    this.secondFormGroup = this._formBuilder.group({
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: [new Date],
    });

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });


  }

  get secondFormGroupValidation() { return this.secondFormGroup.controls; }

  continue() {

    this.submitted = true;

    if (this.triptype === 'Round Trip') {
      if (this.checkDate(this.secondFormGroup.get('pickupDate').value, this.secondFormGroup.get('returnDate').value)) {
        alert('Enter valid Return Date');
        return
      }
    }

    if (!this.origin || !this.destination) {
      alert('Enter valid Pickup or Drop Address');
      return
    }

    if (this.secondFormGroup.invalid) {
      return
    }

    let rideDetails = {
      'triptype': this.triptype,
      ...this.secondFormGroup.value,
      ...this.routeDetails
    }

    this.taxiService.secondFormGroup.next(rideDetails);
    this.router.navigateByUrl('search-cabs');
    console.log(this.secondFormGroup.value);
  }

  selectTrip(type) {
    this.triptype = type;
  }

  onResponses(event) {
    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text,
      'distance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "")
    }
  }

  checkDate(start, end) {
    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
  }



  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;


        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
        }

        this.zoom = 12;
      });
    });
  }

  isDisableTime(selectedTime: string): boolean {
    if (moment(this.secondFormGroup.get('pickupDate').value).isSame(Date.now(), 'day')) {
      let isTimeOver = false;
      let currentHour = new Date().getHours() + 2;
      let currentMin = new Date().getMinutes();
      let currentAmPm = currentHour >= 12 ? 'PM' : 'AM';
      currentHour = currentHour % 12;
      let selectedHour = +selectedTime.substr(0, 2) % 12;
      let selectedMin = +selectedTime.substr(3, 2);
      let selectedAM_PM = selectedTime.substr(6, 2);
      if (currentAmPm === selectedAM_PM) {
        if (selectedHour < currentHour) {
          isTimeOver = true;
        } else if (selectedHour === currentHour) {
          if (selectedMin < currentMin) {
            isTimeOver = true;
          }
        }
      } else {
        isTimeOver = currentAmPm > selectedAM_PM;
      }
      return isTimeOver;
    } else {
      return false
    }
  }

}
