export const POPULAR_CITIES = [
    {
        cityName: 'Chennai',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/7rPx06Qp7SrV0tJ2jO9lRq/570d83276c10822db860c233d5e0fe58/image.png'
    },
    {
        cityName: 'Coimbatore',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/3ho2ApLj0KlcgTY0Avxbvq/7e04cb13bc1385a93dfa4f189fd41919/image__1_.jpg'
    },
    {
        cityName: 'Madurai',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1CYLlX6zchayL2dokBDo2j/5b89e4845fa371a0119c6ea27afc5eaf/image__2_.jpg'
    },
    {
        cityName: 'Salem',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/74ZO3Z09rDKvdTvhZxV5Be/97a67f5bf5817292fa8d35bce6104a5a/image__5_.jpg'
    },
    {
        cityName: 'Trichy',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/7MJgZy1gRQ3p2HjYb8uBOz/3a0f6a1ab49fcfcaba593418d77d2eaf/image__4_.jpg'
    },
    {
        cityName: 'Pondicherry',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1C6nib3U53pY0H2GgTVNOV/f0ca51baf685d0b55cc6d3c4f413e426/image__3_.jpg'
    },
    {
        cityName: 'Vellore',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/7bBQwe5R4b1nA6JJYWzkKm/bac96b8f7253ab812a7670a7159eb07e/image__6_.jpg'
    },
    {
        cityName: 'Thanjavur',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/uXxjoVGR1rzOVkHdS5BGr/f5daaec48a3b3af5a7bf53ed0b221137/image__7_.jpg?'
    }
]

export const POPULAR_ROUTES = [
    {
        origin: 'Chennai',
        destination: 'Coimbatore',
        distance: 505.5,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/iHGYg1mPmglkpSyoPv157/13fe19adbdc0e6f3f2c5a4c61740cc99/TmaUkl.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Salem',
        distance: 345,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1m2o5NFAAXLLBwTskqmi8r/b34b58b53d2debc0e74eec5cc95af2ce/salem__1_.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Trichy',
        distance: 331.8,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/2ZJpWcZeKuezj1NQTPinvY/cfb6a5e82a51c4fbe128db2cdf259b3f/srirangam-temple-city-trichy.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Pondicherry',
        distance: 165.0,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/55uOjqGoGKyZwaYoHLeHM5/d5ca3c064c70c69c288c0859061fe7df/pondicherry-1061484_1280.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Bangalore',
        distance: 347.4,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/2lr4NASVtNHUE4rc58eG6m/bb8c831fbe079843d939c814ccb75817/pexels-photo-8529425.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Madurai',
        distance: 462.3,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1CYLlX6zchayL2dokBDo2j/5b89e4845fa371a0119c6ea27afc5eaf/image__2_.jpg'
    },
    {
        origin: 'Chennai',
        destination: 'Tiruvannamalai',
        distance: 194,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/6HLzBPT4pmlv4KtHSBSbj/5d6a093458d08bbe5b54aa32d9c408d2/-70364_8196.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Tirupati',
        distance: 133,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/5h1Fbiu0QCTXOfojuV1Ofw/2d034605d510bfca2c5fa60616e06c70/wp6076037.jpeg'
    },
    {
        origin: 'Coimbatore',
        destination: 'Ooty',
        distance: 85.5,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/6keAizc7R4GxdKWLp9Qaiz/e55a9964d869e67fbe434a3664718990/53560wide.jpeg'
    },
    {
        origin: 'Trichy',
        destination: 'Chennai',
        distance: 330.3,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/2ZJpWcZeKuezj1NQTPinvY/cfb6a5e82a51c4fbe128db2cdf259b3f/srirangam-temple-city-trichy.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Dharmapuri',
        distance: 300.4,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/3nibmQ6hpact5qnGnkhzDh/cac1397801b7a01c686b2c654da49b0c/hogenakkal-mountain-rocks-132491760.jpeg'
    },
    {
        origin: 'Chennai',
        destination: 'Pollachi',
        distance: 527.7,
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/7py9ckaj4zrCP3MWe3WSDc/85d8948a5cb2b29094dcd977e2e27675/india-valparai-nature-love-thumbnail.jpeg'
    },
];

export const tamilnadu_districts = [
    {
        city: 'Ariyalur',
        image: 'assets/cities/ariyalur.jpeg'
    },
    {
        city: 'Chengalpattu',
        image: 'assets/cities/chengalpattu.jpeg'
    },
    {
        city: 'Chennai',
        image: 'assets/cities/chennai.jpeg'
    },
    {
        city: 'Coimbatore',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/iHGYg1mPmglkpSyoPv157/13fe19adbdc0e6f3f2c5a4c61740cc99/TmaUkl.jpeg'
    }, {
        city: 'Cuddalore',
        image: 'assets/cities/Cuddalore.jpeg'
    }, {
        city: 'Dharmapuri',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/3nibmQ6hpact5qnGnkhzDh/cac1397801b7a01c686b2c654da49b0c/hogenakkal-mountain-rocks-132491760.jpeg'
    }, {
        city: 'Dindigul',
        image: 'assets/cities/Dindigul.jpeg'
    }, {
        city: 'Kanchipuram',
        image: 'assets/cities/Kanchipuram.jpeg'
    }, {
        city: 'kanyakumari',
        image: 'assets/cities/Kanniyakumari.jpeg'
    }, {
        city: 'Karur',
        image: 'assets/cities/Karur.jpeg'
    }, {
        city: 'Krishnagiri',
        image: 'assets/cities/Krishnagiri.jpeg'
    }, {
        city: 'Madurai',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1CYLlX6zchayL2dokBDo2j/5b89e4845fa371a0119c6ea27afc5eaf/image__2_.jpg'
    }, {
        city: 'Nagapattinam',
        image: 'assets/cities/Nagapattinam.jpeg'
    }, {
        city: 'Namakkal',
        image: 'assets/cities/Namakkal.jpeg'
    }, {
        city: 'Perambalur',
        image: 'assets/cities/Perambalur.jpeg'
    }, {
        city: 'Pudukkottai',
        image: 'assets/cities/Pudukkottai.jpeg'
    }, {
        city: 'Ramanathapuram',
        image: 'assets/cities/Ramanathapuram.jpeg'
    }, {
        city: 'Salem',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/1m2o5NFAAXLLBwTskqmi8r/b34b58b53d2debc0e74eec5cc95af2ce/salem__1_.jpeg'
    }, {
        city: 'Sivaganga',
        image: 'assets/cities/Sivaganga.jpeg'
    }, {
        city: 'Thanjavur',
        image: 'assets/cities/Thanjavur.jpeg'
    }, {
        city: 'Theni',
        image: 'assets/cities/Theni.jpeg'
    }, {
        city: 'Nilgiris',
        image: 'assets/cities/ooty.jpeg'
    }, {
        city: 'Ooty',
        image: 'assets/cities/ooty.jpeg'
    }, {
        city: 'Thiruvallur',
        image: 'assets/cities/Thiruvallur.jpeg'
    }, {
        city: 'Thiruvarur',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/uXxjoVGR1rzOVkHdS5BGr/f5daaec48a3b3af5a7bf53ed0b221137/image__7_.jpg?'
    }, {
        city: 'Thoothukkudi',
        image: 'assets/cities/Thoothukkudi.jpeg'
    }, {
        city: 'Trichy',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/7MJgZy1gRQ3p2HjYb8uBOz/3a0f6a1ab49fcfcaba593418d77d2eaf/image__4_.jpg'
    }, {
        city: 'Tirunelveli',
        image: 'assets/cities/Tirunelveli.jpeg'
    }, {
        city: 'Tiruppur',
        image: 'assets/cities/Tiruppur.jpeg'
    }, {
        city: 'Tiruvannamalai',
        image: 'assets/cities/Tiruvannamalai.jpeg'
    }, {
        city: 'Vellore',
        image: 'assets/cities/Vellore.jpeg'
    }, {
        city: 'Viluppuram',
        image: 'assets/cities/Viluppuram.jpeg'
    }, {
        city: 'Virudhunagar',
        image: 'assets/cities/Virudhunagar.jpeg'
    },
    {
        city: 'Bangalore',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/2lr4NASVtNHUE4rc58eG6m/bb8c831fbe079843d939c814ccb75817/pexels-photo-8529425.jpeg'
    },

    {
        city: 'Tirupati',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/5h1Fbiu0QCTXOfojuV1Ofw/2d034605d510bfca2c5fa60616e06c70/wp6076037.jpeg'
    },
    {
        city: 'Pondicherry',
        image: 'https://images.ctfassets.net/hkv6akvsgg6u/55uOjqGoGKyZwaYoHLeHM5/d5ca3c064c70c69c288c0859061fe7df/pondicherry-1061484_1280.jpeg'
    },
    {
        city: 'Ranipet',
        image: 'assets/cities/ranipet.jpeg'
    },
    {
        city: 'Tenkasi',
        image: 'assets/cities/tenkasi.jpeg'
    },
    {
        city: 'Thoothukudi',
        image: 'assets/cities/thoothukudi.jpeg'
    },
    {
        city: 'Tirupathur',
        image: 'assets/cities/tirupathur.jpeg'
    },
    {
        city: 'Tiruvallur',
        image: 'assets/cities/tiruvallur.jpeg'
    },
    {
        city: 'Tiruvarur',
        image: 'assets/cities/tiruvarur.jpeg'
    },
    {
        city: 'Erode',
        image: 'assets/cities/Erode.webp'
    },
    {
        city: 'Kallakurichi',
        image: 'assets/cities/Kallakurichi.jpeg'
    }




];


export const allCities = [
    "Coimbatore",
    "Chengalpattu",
    "Kanchipuram",
    "Madurai",
    "Vellore",
    "Tiruvannamalai",
    "Salem",
    "Tirunelveli",
    "Trichy",
    "Ariyalur",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Sivagangai",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tirupattur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvarur",
    "Viluppuram",
    "Virudhunagar",
    "Bangalore",
    "Pondicherry"
];