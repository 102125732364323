<header id="header" class="site-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-6 col-12">
                <div class="site">
                    <div class="site__menu">
                        <a title="Menu Icon" href="#" class="site__menu__icon">
                            <i class="las la-bars la-24-black"></i>
                        </a>
                        <div class="popup-background"></div>
                        <div class="popup popup--left">
                            <a title="Close" href="#" class="popup__close">
                                <i class="las la-times la-24-black"></i>
                            </a>
                            <!-- .popup__close -->
                            <div class="popup__content">
                                <div class="popup__user popup__box open-form">
                                    <a title="Login" href="tel:{{settings.phoneNumber}}">Need Help ? </a>
                                    <a title="Sign Up" href="https://wa.me/{{settings.whatsapp}}">Whatsapp</a>
                                </div>
                                <!-- .popup__user -->
                                <div class="popup__destinations popup__box">
                                    <ul class="menu-arrow">
                                        <li>
                                            <a title="Destinations" href="#">Destinations </a>
                                            <ul class="sub-menu">
                                                <li *ngFor="let city of allCities"><a
                                                        href="https://{{city.city}}.azhagudroptaxi.com"
                                                        title="{{city.city}}">{{city.city}}</a></li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="popup__menu popup__box">
                                    <ul class="menu-arrow">
                                        <li>
                                            <a href="https://{{settings.websiteAddress}}"
                                                title="{{settings.siteName}}">Home</a>
                                        </li>
                                        <li>
                                            <a href="https://{{settings.websiteAddress}}/about"
                                                title="{{settings.siteName}}">About</a>
                                        </li>
                                        <li>
                                            <a href="https://{{settings.websiteAddress}}/tariff"
                                                title="{{settings.siteName}}">Tariff</a>
                                        </li>
                                        <li>
                                            <a href="https://{{settings.websiteAddress}}/contact"
                                                title="{{settings.siteName}}">Contact</a>
                                        </li>

                                    </ul>
                                </div>
                                <!-- .popup__menu -->
                            </div>

                        </div>
                        <!-- .popup -->
                    </div>
                    <!-- .site__menu -->
                    <div class="site__brand">
                        <a title="Logo" href="/" class="site__brand__logo"><img src="assets/logo.png"
                                alt="Drop Taxi Oneway"></a>
                    </div>
                    <!-- .site__brand -->

                </div>
                <!-- .site -->
            </div>
            <!-- .col-md-6 -->
            <div class="col-xl-6 col-4">
                <div class="right-header align-right">
                    <nav class="main-menu">
                        <li>
                            <a routerLink="/home" title="Demos">Home</a>
                        </li>
                        <li>
                            <a routerLink="/about" title="Demos">About</a>
                        </li>
                        <li>
                            <a routerLink="/tariff" title="Demos">Tariff</a>
                        </li>
                        <li>
                            <a routerLink="/contact" title="Demos">Contact</a>
                        </li>
                    </nav>

                    <!-- .right-header__login -->

                    <div class="right-header__button btn">
                        <a title="Add place" href="tel:{{settings.phoneNumber}}">
                            <i class="las la-plus la-24-white"></i>
                            <span>Call {{settings.phoneNumber}}</span>
                        </a>
                    </div>
                    <!-- .right-header__button -->
                </div>
                <!-- .right-header -->
            </div>
            <!-- .col-md-6 -->
        </div>
        <!-- .row -->
    </div>
    <!-- .container-fluid -->
</header>
<!-- .site-header -->